<template>
  <b-container
    v-if="loaded"
    class="websites-page-component"
  >
    <masthead
      class="websites-masthead-mobile"
      title="Websites"
      group-buttons
      @changed="searchChanged"
    />
    <add-domains :domains="domains" />
    <domain-list
      :domains="validDomains"
      :loading="loading"
      :cards="cards"
      :search-string="query"
      @manage-domain="manageDomain"
      @manage-email="manageEmail"
      @site-builder="showSiteBuilderModal"
      @lock-domain="showDomainLockModal"
      @renew-domain="showDomainRenewModal"
      @refresh-domains="refreshDomains"
    />
    <domain-lock-modal
      :selected-domain="selectedDomain"
      @cancel="cancelDomainLockModal"
      @ok="confirmDomainLockModal"
    />
    <domain-renew-modal
      :renew-price="selectedRenewPrice"
      :loading="loadingRenew"
      :show-payment-select="showPaymentSelect"
      :selected-payment-method="selectedPaymentMethod"
      :auto-pay-id="selectedDomain?.autopay_id"
      @renew="renew"
      @cancel="hideDomainRenewModal"
    />
    <hosting-login-info-modal
      :hosting-info="selectedDomain?.domain_hosting_info"
      @close="closeHostingInfoModal"
      @website-login-interaction="logWebsiteLoginInteraction"
    />
  </b-container>
  <ct-centered-spinner v-else />
</template>

<script>
import Masthead from '@/components/shared/Masthead'
import DomainLockModal from '@/components/Domains/DomainLockModal'
import DomainRenewModal from '@/components/Domains/DomainRenewModal.vue'
import HostingLoginInfoModal from '@/components/Services/Hosting/HostingLoginInfoModal'
import DomainList from '@/pages/Websites/DomainList.vue'
import AddDomains from '@/pages/Websites/AddDomains.vue'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { mapActions, mapGetters } from 'vuex'

import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

import search from '@/components/shared/Search.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'

export default {
  name: 'Websites',
  components: { CtCenteredSpinner, Masthead, DomainLockModal, DomainRenewModal, HostingLoginInfoModal, DomainList, AddDomains },
  mixins: [makeToastMixin],
  data() {
    return {
      selectedDomain: null,
      dataCount: null,
      domains: null,
      query: '',
      loaded: false,
      loading: false,
      selectedRenewPrice: null,
      loadingRenew: false,
      showPaymentSelect: false,
    }
  },
  computed: {
    search() {
      return search
    },
    validDomains() {
      return this.domains.filter(domain => this.validDomainConditions(domain))
    },
    ...mapGetters('paymentMethods', [
      'automatedClearingHouses',
      'cards',
      'selectedPaymentMethod',
    ]),
  },
  async mounted() {
    this.domains = await this.fetchDomains()
    await this.loadMore()
    this.loaded = true
    while(this.dataCount == null || this.dataSharedCount == null) {
      await new Promise( r => setTimeout(r, 200) )
    }
    this.tabIndex = this.dataCount === 0 && this.dataSharedCount > 0 ? 1:0
  },
  methods: {
    ...mapActions('domains', [
      'lockDomain',
      'unlockDomain',
      'fetchDomains',
      'getRenewalPrice',
      'renewDomain',
      'setDomain',
    ]),
    ...mapActions('companies', [
      'loadMore',
    ]),
    async refreshDomains() {
      this.domains = await this.fetchDomains()
    },
    validDomainConditions(domain) {
      return ['active', 'suspended', 'external'].includes(domain.status)
    },
    manageDomain(item) {
      this.selectedDomain = item
      this.logInteraction('manage-domain-page', 'visit', 'business-domain')
      this.$router.push({ name: 'website', params: { id: item.id } })
    },
    manageEmail(item) {
      this.selectedDomain = item
      this.logInteraction('manage-email-page', 'visit', 'business-email')
      this.$router.push({ name: 'website', params: { id: item.id, activeTab: 'email' } })
    },
    cancelDomainLockModal() {
      this.selectedDomain = null
      this.$bvModal.hide('domain-lock-modal')
    },
    confirmDomainLockModal() {
      this.toggleLock(this.selectedDomain)
      this.$bvModal.hide('domain-lock-modal')
    },
    showDomainLockModal(item) {
      if (item.status !== 'external') {
        this.selectedDomain = item
        this.$bvModal.show('domain-lock-modal')
      }
    },
    showDomainRenewModal(item) {
      this.selectedDomain = item
      this.showPaymentSelect = !item.autopay_id

      this.getPrice(item)
      this.$bvModal.show('domain-renew-modal')
    },
    hideDomainRenewModal() {
      this.selectedDomain = null
      this.loadingRenew = false
      this.$bvModal.hide('domain-renew-modal')
    },
    showSiteBuilderModal(item) {
      this.selectedDomain = item
      this.$bvModal.show('hosting-login-info-modal')
    },
    closeHostingInfoModal() {
      this.$bvModal.hide('hosting-login-info-modal')
    },
    async toggleLock(domain) {
      try {
        domain.locked ?
          await this.unlockDomain({ id: domain.id }) :
          await this.lockDomain({ id: domain.id })
        const action = this.selectedDomain.locked ? "lock" : "unlock"
        this.logInteraction('business-domain-lock', action, 'business-domain')
      } catch (err) {
        this.errorToast('Error', 'There was an error updating your domain lock status.')
        return
      }
    },
    async getPrice(domain) {
      try {
        this.selectedRenewPrice = null
        this.selectedRenewPrice = await this.getRenewalPrice({ domain: domain })
      } catch {
        this.errorToast('Error', 'There was an error getting your domain renewal price.')
        this.hideDomainRenewModal()
      }
    },
    async renew() {
      this.loadingRenew = true
      const payable_id = this.selectedDomain.autopay_id || this.selectedPaymentMethod.id
      const response = await this.renewDomain({ domain: this.selectedDomain, autopay_id: payable_id })

      response?.data?.success ?
        this.successfulRenew(response.data.response.expiry_date) :
        this.errorToast('Error', "Error renewing domain.")

      this.loadingRenew = false
      this.logInteraction('business-domain-renewal', "renew", 'business-domain')
      this.$bvModal.hide('domain-renew-modal')
    },
    successfulRenew(date) {
      this.selectedDomain.expire_date = date
      this.selectedDomain.renew_date = date
      this.setDomain(this.selectedDomain)

      this.successToast('Success', 'Domain renewal complete.')
    },
    searchChanged(queryObject) {
      this.query = queryObject.query
    },
    async refresh(response) {
      this.loading = true
      this.domains = await this.fetchDomains()
      this.loading = false

      response.data.success ?
        this.successToast('Complete', `${response.data.result.message}`) :
        this.errorToast('Error', `${response.data.result.message}`)
    },
    async logWebsiteLoginInteraction() {
      await this.logInteraction('business-website-login', 'login', 'business-website')
    },
    async logInteraction(name, action, subCategory) {
      try {
        this.clientInteractionLog = await createOrFindClientInteractionLog({
          category: 'business-identity',
          subCategory,
          objectTable: 'Domain',
          objectId: this.selectedDomain.id,
          companyId: this.selectedDomain.company_id,
          interaction: {
            name,
            action,
            type: 'button',
            adminUser: sessionStorage.getItem('admin-logged-in'),
          },
        })
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') { console.error(error) }
      }
    },
  },
}
</script>
<style lang="scss">
  .websites-page-component {
    max-width: 1280px;

    .table-responsive {
      overflow-y: unset;
      td {
        vertical-align: middle;
        .expandable-row {
          padding-left: 0 !important;
        }
        &[aria-colindex="6"] .expandable-row {
          text-align: center;
        }
      }
    }

    @media only screen and (max-width: 735px) {
      td.mobile-hidden {
        display: none !important;
      }
    }

    .link-button {
      cursor: pointer;
      text-decoration: none;
      display: inline-flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }

    .suspended {
      color: #fd7e14;

      &:hover {
        color: #b9590a;
      }
    }
    .processing {
      color: $ct-ui-color-24;

      &:hover {
        color: $ct-ui-color-26;
      }
    }
    .not-suspended {
      color: #2e3798;

      &:hover {
        color: #0056b3;
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .websites-page-component {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;

      .websites-masthead-mobile {
        width: 100%;

        display: flex;
        flex-flow: column nowrap;
        justify-self: center;
        align-items: center;

        .websites-add-website-btn {
          font-size: 0.875em;
        }
      }
    }
  }
</style>
